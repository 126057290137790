.logo {
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 256px;
}

.heading {
    align-self: flex-start;
    color: white;
}

.deck-builder {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-bottom: 64px;
    max-width: 272px;
    margin: auto;
    align-items: stretch;
}

.deck {
    background-color: #005C90;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0px 16px;
    align-items: stretch;
}

.edit-button {
    align-self: stretch;
    color: white;
    background-color: transparent;
    font-weight: bold;
    font-size: small;
    border: none;
    padding: 0px 12px 0px 8px;
    border-left: rgba(255, 255, 255, 0.5);
    border-width: 0px;
    border-left-width: 2px;
    border-style: solid;
    cursor: pointer;
}

.home-button {
    align-self: center;
    background-color: #005C90;
    margin-top: 32px;
    padding: 8px 16px;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    user-select: none;
    font-size: small;
    text-decoration: none;
}

.card-row {
    padding: 8px;
    background-color: #7099B5;
    border-radius: 8px;
    color: white;
    font-size: small;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.card-row-empty {
    color: #356690;
    background-color: #356690;
    cursor: default;
    user-select: none;
}

.share {
    align-items: stretch;
    display: flex;
}

.share>button {
    flex: 1;
    padding: 8px;
    color: white;
    background-color: #005C90;
    font-weight: bold;
    font-size: small;
    margin: 4px;
    border-radius: 8px;
    border-style: none;
    cursor: pointer;
}

.import-export {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    width: 100vw;
    height: 100vh;
    max-width: 256px;
    max-height: 512px;
    position: fixed;
    top: 35%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.import-export>textarea {
    flex: 1;
    resize: none;
    background-color: transparent;
    border: none;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 16px;
}

.import-export>div {
    margin: 16px;
    display: flex;
    justify-content: space-evenly;
}

.import-export>div>button {
    flex: 1;
    padding: 8px;
    color: white;
    background-color: #005C90;
    font-weight: bold;
    font-size: small;
    margin: 4px;
    border-radius: 8px;
    border-style: none;
    cursor: pointer;
}

.import-export>div>button:disabled {
    cursor: auto;
    opacity: 50%;
}