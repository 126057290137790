body {
    background-color: #003a71;
}

footer {
    position: fixed;
    bottom: 0px;
    padding: 8px;
    color: white;
    font-weight: lighter;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}