.main {
    display: flex;
    flex-direction: column;
}

.filter {
    background-color: #235C8D;
    padding: 8px;
    display: flex;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 8px;
    border-radius: 8px;
    margin: 8px;
    z-index: 10;
}

.search {
    padding: 8px;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    flex-shrink: 1;
    min-width: 0;
}

input[type="search"] {
    -webkit-appearance: none;
}

.card-list-wrapper {
    background-color: #003A71;
    display: flex;
    justify-content: center;
    padding: 16px 0px;
}

.card-list {
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 8px;
    flex-shrink: 1;
    min-width: 0px;
    margin: 0px 8px;
}

.card {
    position: relative;
    color: white;
    padding: 8px;
    display: flex;
    gap: 8px;
    background-color: rgb(131, 162, 187);
    border-radius: 8px;
}

.card-selected {
    background-color: rgb(131, 162, 187, 0.5);
}

.card-image {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
}

.card-label {
    flex-grow: 1;
}

.card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-title {
    font-weight: bold;
}

.card-subtitle {
    padding-left: 8px;
    font-size: small;
}

.footer {
    background-color: #235C8D;
    position: sticky;
    bottom: 0px;
}

.deck {
    display: flex;
    gap: 8px;
    padding: 8px;
    overflow-x: auto;
    margin: auto;
    max-width: fit-content;
}

.deck-card {
    height: 56px;
    width: 56px;
    flex-shrink: 0;
    position: relative;
}

.icon {
    height: 20px;
    width: 20px;
    color: #F50000;
    position: absolute;
    top: 4px;
    left: 4px;
}

.deck-card>.icon {
    top: -4px;
    left: -4px;
}

.options {
    display: flex;
    max-width: fit-content;
    margin: auto;
    gap: 64px;
    padding: 16px 8px;
}

.option {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

.deck-count {
    padding: 4px;
    background-color: rgb(0, 0, 0, 0.5);
    color: white;
    height: fit-content;
    position: sticky;
    left: 0px;
    z-index: 10;
    border-radius: 8px;
}