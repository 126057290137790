.input-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    color: white;
    max-width: 1200px;
    padding: 32px;
}

.input-container>textarea {
    align-self: stretch;
    min-height: 800px;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    outline: none;
    color: white;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 16px;
}