/** Home */

.logo {
    display: block;
    margin: 96px auto 80px auto;
    width: 320px;
}

.wrapper {
    text-align: center;
    color: white;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 512px;
    margin: auto;
    margin-bottom: 64px;
}

.button {
    min-width: 256px;
    padding: 8px;
    margin: 8px;
    border-radius: 4px;
    background-color: #235c8d;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.versus {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 16px;
    border-radius: 4px;
    color: #235c8d;
    padding: 16px;
    font-size: 16px;
    justify-content: center;
    align-self: stretch;
}

.versus-button {
    background-color: #235c8d;
    color: white;
    padding: 8px;
    border-radius: 4px;
    font-size: 16px;
    vertical-align: middle;
    border: none;
    margin: 8px;
}

.versus-link {
    flex: 1;
    margin: 0px 8px;
    border-radius: 4px;
    background-color: white;
    color: #235c8d;
    font-weight: bold;
    padding: 8px;
    border: none;
    text-align: center;
}

.versus-link-ph {
    color: gray;
}

.discord {
    width: 150px;
    margin-top: 16px;
}