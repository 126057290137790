.main {
    height: 100vh;
    height: calc(var(--app-height, 100vh));
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.panel {
    background-color: #005C90;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-shrink: 1;
    overflow-y: auto;
}

.title {
    margin: 0;
    color: white;
    padding: 8px;
}

.panel-children {
    background-color: #003a71;
    border-radius: 0px 0px 8px 8px;
    flex-grow: 1;
    overflow-y: auto;
}

.panel-lobby {
    flex: 2;
}

.panel-challenges {
    flex: 1;
}

.panel-chat {
    flex: 2;
}

.spacer {
    height: 16px;
}

.home {
    max-width: 200px;
    min-width: 100px;
    text-align: center;
    align-self: center;
    background-color: #005C90;
    color: white;
    padding: 8px;
    border-radius: 8px;
    text-decoration: none;
}


/* accepted challenge */

.accepted-challenge {
    position: fixed;
    width: min(100vw, 400px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.accepted-challenge-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 24px;
    margin: 8px;
    background-color: #003a71;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 8px;
}

.play {
    max-width: 200px;
    min-width: 100px;
    text-align: center;
    align-self: center;
    background-color: #005C90;
    color: white;
    padding: 8px;
    border-radius: 8px;
    text-decoration: none;
}


/* people */

.people {
    padding: 8px;
    color: white;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    column-gap: 24px;
    row-gap: 8px;
    overflow-y: auto;
}

.person {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.person-challenge {
    cursor: pointer;
    background-color: #005C90;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}


/* challenges */

.challenges {
    padding: 8px;
    color: white;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    column-gap: 24px;
    row-gap: 8px;
    overflow-y: auto;
}

.challenge {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* chat */

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.messages {
    overflow-y: auto;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 8px;
}

.messages::-webkit-scrollbar {
    width: 1em;
}

.messages::-webkit-scrollbar-track {
    background: transparent;
}

.messages::-webkit-scrollbar-thumb {
    border-radius: 2em;
    border: #003a71 solid 0.3em;
    background-color: rgba(255, 255, 255, 0.2);
}

.message {
    color: #FEFEFE;
}

.chat-input {
    background-color: #005C90;
    padding-top: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.chat-textbox {
    display: flex;
    flex: 1;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 4px;
    border-radius: 8px;
}

.chat-textbox>textarea {
    resize: none;
    outline: none;
    border: none;
    flex: 1;
    background-color: transparent;
}

.chat-textbox>textarea::-webkit-scrollbar {
    width: 0.5em;
}

.chat-textbox>textarea::-webkit-scrollbar-track {
    background: transparent;
}

.chat-textbox>textarea::-webkit-scrollbar-thumb {
    border-radius: 2em;
    background-color: rgba(255, 255, 255, 0.2);
}

.chat-send {
    padding: 8px 16px;
    background-color: #003a71;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    flex-grow: 0;
}

.chat-spacer {
    min-height: 0px;
    flex-grow: 1;
    flex-shrink: 1;
}