/* .game {
    height: 768px;
    width: 1024px;
} */

.game {
    height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    align-items: center;
    flex-direction: column;
}

svg {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

svg text::selection {
    background: none;
}

.button:hover {
    fill-opacity: 100%;
}

.shadow {
    filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.6));
}