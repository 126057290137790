.logo {
    display: block;
    margin: 96px auto 80px auto;
    width: 320px;
}

.username-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
}

.username-label {
    color: white;
    font-size: x-large;
    align-self: flex-start;
    padding: 0px 16px;
}

.username-select {
    display: flex;
    align-self: stretch;
}

.setup-form {
    display: flex;
    align-self: center;
}

.shuffle-username {
    background-color: #235c8d;
    padding: 8px;
    margin: 16px 8px;
    border-radius: 4px;
    cursor: pointer;
    color: #b2d2e4;
    font-size: large;
}

.shuffle-username>svg {
    padding-left: 6px;
}

.submit {
    background-color: #235c8d;
    padding: 8px;
    margin: 16px 8px;
    min-width: 64px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    color: #b2d2e4;
    font-size: large;
    text-decoration: none;
}

.dropdown-wrapper {
    flex: 1 1 0px;
}

.dropdown-header {
    margin: 16px;
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.dropdown-list {
    max-height: 240px;
    overflow-y: auto;
    padding: 0;
    border-radius: 8px;
}

.dropdown-list li {
    list-style: none;
    width: 100%;
}

.dropdown-item {
    background-color: white;
    padding: 8px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: silver;
}