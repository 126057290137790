.logo {
    display: block;
    margin: 96px auto 80px auto;
    width: 320px;
}

.wrapper {
    color: white;
    max-width: 500px;
}

.wrapper>a:visited {
    color: white;
}

.wrapper>a:link {
    color: white;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 512px;
    margin: auto;
    margin-bottom: 64px;
}

.button {
    text-align: center;
    width: 256px;
    padding: 8px;
    margin: 8px;
    border-radius: 4px;
    background-color: #235c8d;
    text-decoration: none;
    color: white;
    cursor: pointer;
}
